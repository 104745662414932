@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;400;700&display=swap);
* {
  font-family: 'Noto Sans KR', sans-serif;
  box-sizing: border-box;
}
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  background-color: #343434;
  line-height: 1.4;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input[type="text"],
input[type="text"]:focus   
{
  background: transparent;
  border: none;
  outline-width: 0;
}
input[type="password"],
input[type="password"]:focus   
{
  background: transparent;
  border: none;
  outline-width: 0;
}
button {
  border: 0;
  padding: 0;
}
